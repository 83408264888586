import React from 'react';
import './AdditionalFeatures.css';

function AdditionalFeatures({ features, setFeatures }) {
  const additionalFeatures = [
    { id: 'shopPayInstallments', name: 'Shop Pay Installments', cost: 15 },
  ];

  const handleToggle = (featureId) => {
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [featureId]: !prevFeatures[featureId],
    }));
  };

  return (
    <div className="additional-features">
      <h2>Additional features</h2>
      {additionalFeatures.map((feature) => (
        <div key={feature.id} className="feature-item">
          <div className="feature-info">
            <span className="feature-name">{feature.name}</span>
            <span className="feature-cost">${feature.cost}</span>
          </div>
          <label className="switch">
            <input
              type="checkbox"
              checked={features[feature.id] || false}
              onChange={() => handleToggle(feature.id)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default AdditionalFeatures;

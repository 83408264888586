import React, { useState } from 'react';
import './NavBar.css';

function NavBar() {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipText = "This app helps you calculate the monthly costs for your Shopify store. Select your plan, apps, and features to see the total cost.";

  return ( 
    <nav className="navbar">
      <div className="navbar-left">
        <span className="navbar-logo">Troml</span>
      </div>
      <div className="navbar-right">
        <div className="help-button-container">
          <button 
            className="help-button"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            Help
          </button>
          {showTooltip && (
            <div className="tooltip">
              {tooltipText}
            </div>
          )}
        </div>
        <div className="help-button-container">
          <a href="mailto:david@zqdo.info" className="help-button contact-link">Contact</a>
        </div>
        <a 
          href="https://zqdo.info/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="shopify-expert-services-button"
        >
          Shopify Expert Services
        </a>
      </div>
    </nav>
  );
}

export default NavBar;

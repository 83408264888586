import React from 'react';

function ShopifyPlanSelector({ selectedPlan, setSelectedPlan }) {
  const plans = [
    { id: 'basic', name: 'Basic Shopify', price: 29 },
    { id: 'shopify', name: 'Shopify', price: 79 },
    { id: 'advanced', name: 'Advanced Shopify', price: 299 },
  ];

  return (
    <div className="shopify-plan-selector">
      <h2>Shopify plan</h2>
      <div className="plan-options">
        {plans.map((plan) => (
          <label key={plan.id} className="plan-option">
            <input
              type="radio"
              name="shopifyPlan"
              value={plan.id}
              checked={selectedPlan === plan.id}
              onChange={() => setSelectedPlan(plan.id)}
            />
            <span className="plan-details">
              <span className="plan-name">{plan.name}</span>
              <span className="plan-price">${plan.price}/month</span>
            </span>
          </label>
        ))}
      </div>
    </div>
  );
}

export default ShopifyPlanSelector;

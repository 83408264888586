import React from 'react';
import './ThankYouMessage.css';

function ThankYouMessage() {
  return (
    <div className="thanks-message">
      Thanks for using this tool! If you need help with your Shopify store,{' '}
      <a href="https://zqdo.info/" target="_blank" rel="noopener noreferrer">
        feel free to reach out!
      </a>
    </div>
  );
}

export default ThankYouMessage;

import React from 'react';
import './AppsSelector.css';

function AppsSelector({ apps, setApps }) {
  const availableApps = [
    { id: 'klaviyo', name: 'Klaviyo', cost: 20 },
    { id: 'yopto', name: 'Yopto', cost: 15 },
    { id: 'privy', name: 'Privy', cost: 12 },
    { id: 'recharge', name: 'Recharge', cost: 99 },
    { id: 'smile', name: 'Smile', cost: 49 },
    { id: 'judgeme', name: 'Judge.me', cost: 15 },
  ];

  const handleToggle = (appId) => {
    setApps((prevApps) => ({
      ...prevApps,
      [appId]: !prevApps[appId],
    }));
  };

  return (
    <div className="apps-selector">
      <h2>Apps</h2>
      {availableApps.map((app) => (
        <div key={app.id} className="app-item">
          <div className="app-info">
            <span className="app-name">{app.name}</span>
            <span className="app-cost">${app.cost}</span>
          </div>
          <label className="switch">
            <input
              type="checkbox"
              checked={apps[app.id] || false}
              onChange={() => handleToggle(app.id)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default AppsSelector;

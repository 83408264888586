import React, { useState, useEffect } from 'react';
import './CostCalculator.css';
import ShopifyPlanSelector from './ShopifyPlanSelector';
import AdditionalFeatures from './AdditionalFeatures';
import AppsSelector from './AppsSelector';
import ThankYouMessage from './ThankYouMessage';

function CostCalculator() {
  const [selectedPlan, setSelectedPlan] = useState('basic');
  const [additionalFeatures, setAdditionalFeatures] = useState({});
  const [selectedApps, setSelectedApps] = useState({});
  const [totalCost, setTotalCost] = useState(0);

  const planCosts = {
    basic: 29,
    shopify: 79,
    advanced: 299
  };

  const additionalFeatureCosts = {
    shopPayInstallments: 15
  };

  const appCosts = {
    klaviyo: 20,
    yopto: 15,
    privy: 12,
    recharge: 99,
    smile: 49,
    judgeme: 15
  };

  const calculateCost = () => {
    let cost = planCosts[selectedPlan];

    // Add costs for additional features
    Object.keys(additionalFeatures).forEach(feature => {
      if (additionalFeatures[feature]) {
        cost += additionalFeatureCosts[feature];
      }
    });

    // Add costs for selected apps
    Object.keys(selectedApps).forEach(app => {
      if (selectedApps[app]) {
        cost += appCosts[app];
      }
    });

    setTotalCost(Math.round(cost * 100) / 100); // Round to 2 decimal places
  };

  // Set initial cost when plan changes
  useEffect(() => {
    setTotalCost(planCosts[selectedPlan]);
  }, [selectedPlan]);

  return (
    <div className="cost-calculator">
      <h1>Shopify Monthly Cost Calculator</h1>
      <p className="description">Use this calculator to get a rough estimate of the monthly cost to run your Shopify website.</p>
      
      <ShopifyPlanSelector selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
      <AdditionalFeatures features={additionalFeatures} setFeatures={setAdditionalFeatures} />
      <AppsSelector apps={selectedApps} setApps={setSelectedApps} />
      
      <button className="calculate-button" onClick={calculateCost}>Calculate your cost</button>
      
      <div className="total-cost">
        <h2>Total monthly cost</h2>
        <p className="cost-label">Shopify and App fees</p>
        <p className="cost-amount">${totalCost}</p>
      </div>

      <ThankYouMessage />
    </div>
  );
}

export default CostCalculator;

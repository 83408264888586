import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { useGoogleAnalytics } from './useGoogleAnalytics';
import NavBar from './components/NavBar';
import CostCalculator from './components/CostCalculator';
import './App.css';

function AppContent() {
  useGoogleAnalytics();

  return (
    <div>
      <Helmet>
        <title>Troml | Shopify Calculator</title>
      </Helmet>
      <div className="App">
        <NavBar />
        <div className="content">
          <CostCalculator />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
